h1, h2, h3, h4, h5, h6 {
    display: inline;
}

.event-holder {
    display:flex;
}

.individual-event-left {
    width: 66%;
    border: .5px solid black;
}

.individual-event-right {
    width: 33%;
    border: .5px solid black;
    text-align: "center";
}

.key-item {
    margin: 3px;
    border: 1px solid black;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    display: inline-block;
}

.applications {
    border: .5px solid black;
    text-align: left;
}

.user-profile-link {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}