.table {
    width: 100%;
}

.venue-date {
    width: 10%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.venue-details {
    width: 40%;
    overflow: hidden;
    text-overflow: ellipsis;
}